@import 'src/variables.scss';

$theme-colors: (
  "primary": $color-primary,
  "dark": $color-dark,
);

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

html, body {
  height: 100%;
  width: 100%;
}

h1 {
  margin-top: 0.75rem;
  font-size: 1.8rem;
  font-weight: 300;
}

h2 {
  font-size: 1.7rem;
  font-weight: 300;
}

h3 {
  font-size: 1.6rem;
  font-weight: 300;
}

h4 {
  font-size: 1.5rem;
  font-weight: 300;
}

h5 {
  font-size: 1.4rem;
  font-weight: 300;
}

.display-5 {
  font-weight: 300;
  font-size: 1.75rem;
  text-align: center;
}

// Special states

.clickable {
  cursor: pointer;
}


// Grid layout

.d-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 8px;
  grid-auto-rows: auto;
}

.justify-items-center {
  justify-items: center;
}

@for $i from 2 through 12 {
  .grid-cols-#{$i} {
    grid-template-columns: repeat(#{$i}, 1fr);
  }
}

// NgbModal
.modal.show {
  z-index: 1060;
}

.modal.show .modal-dialog:not(.modal-sm) {
  max-width: 80% !important;
}
.modal.show .modal-dialog.modal-sm {
  max-width: 500px !important;
}

// NgbToast

.ngb-toasts {
  top: 60px !important;
  z-index: 2000 !important;
}
.toast-body {
  white-space: pre-wrap;
}

button:focus,
button.dropdown-toggle:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
select.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus,
.page-link:focus {
  box-shadow: none !important;
  outline: none !important;
}

.ngb-tp-input-container.ngb-tp-hour, .ngb-tp-input-container.ngb-tp-minute {
  .btn {
    padding: 0;
  }
}
.wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  .scroll-wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.dropdown-menu.show {
  z-index: 1060;
}

app-client-cabinet {
  ngx-dadata {
    input {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem !important;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff !important;
      background-clip: padding-box;
      border: 1px solid #ced4da !important;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    &.error input {
      color: #DD1F13 !important;
      border: 1px solid #DD1F13 !important;
      box-shadow: 0 0 0 3px rgba(221, 31, 19, 0.2) !important;
    }
    .autocomplete-items {
      z-index: 2500 !important;
    }
  }
  ngb-datepicker {
    .ngb-dp {
      &-week, &-weekdays, &-header {
        background: #fff !important;
      }
    }
  }
  .ngb-dp-day {
    .custom-day {
      text-align: center;
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      border-radius: 0.25rem;
      &:hover {
        opacity: 0.9;
      }
      &.outside {
        opacity: 0.5;
        &:hover {
          opacity: 0.75 !important;
        }
      }
      &.bg-selected {
        border: 1px solid black;
      }
    }
  }
  .ngb-dp-day:not(.disabled) {
    .custom-day {
      background: green !important;
      color: white !important;
      &.custom-disabled {
        background-color: #d3d3d3 !important;
        color: #6c757d !important;
        opacity: 0.8 !important;
        &:hover {
          opacity: 1 !important;
        }
        &.outside {
          opacity: 0.35 !important;
          &:hover {
            opacity: 0.6 !important;
          }
        }
      }
    }
    &:has(> .custom-disabled) {
      cursor: default;
      pointer-events: none;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

app-delivery-info {
  ngx-dadata {
    input {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem !important;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff !important;
      background-clip: padding-box;
      border: 1px solid #ced4da !important;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    &.error input {
      color: #DD1F13 !important;
      border: 1px solid #DD1F13 !important;
      box-shadow: 0 0 0 3px rgba(221, 31, 19, 0.2) !important;
    }
    .autocomplete-items {
      z-index: 2500 !important;
    }
  }
}

.sticky-container {
  background: white;
  z-index: 1050;
  position: relative;
  .sticky-block {
    padding: 0 10px;
    overflow-y: auto;
    .sticky-table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;
      font-size: 14px;
      display: block;
      overflow: auto;
      height: calc(100vh - 70px);
      padding-bottom: 10px;
      //&::-webkit-scrollbar {
      //  display: none;
      //}
      &-full {
        height: 100vh !important;
      }
      .sticky-table-th, .sticky-table-td {
        padding: 3px 3px 3px 10px;
        border-style: solid;
        border-width: 1px 0 1px 0;
      }
      .sticky-table-th {
        vertical-align: middle;
        background-color: #f2f2f2;
        border-color: #000000;
        position: sticky;
        top: 0;
        z-index: 1050;
        height: 65px;
      }
      .sticky-table-td {
        border-color: #cccccc;
      }
      .xl-th {
        min-width: calc(12.5vw - 2.5rem) !important;
      }
      .xxxl-th {
        min-width: calc(37.5vw - 2.5rem - 20px) !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .form, .form *, .form .ngb-tp-input  {
    font-size: .7rem;
  }
  .sticky-container {
    .sticky-block {
      padding: 0 5px !important;
      .sticky-table {
        font-size: 10px;
        height: calc(100vh - 40px);
        input, select {
          font-size: 11px;
          padding: 3px 6px;
        }
      }
    }
  }
}

